import { Component } from 'react';
import axios from 'axios';

import auth from '../utils/auth';
import DistrictAccount from '../components/DistrictAccount';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { ErrorSnackbar } from 'sharedComponents/Snackbar';
class DistrictAccountContainer extends Component {
  state = {
    district: {},
    schools: [],
    users: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    document.body.classList.add('accounts-page');
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
    document.body.classList.remove('accounts-page');
  }

  fetchData() {
    axios.get(`/api/district/${auth.getUser().profile.district.id}/`).then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({ district: r.data });
      }
    });
    axios.get('/api/user/get_admin_users/').then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({ users: r.data });
      }
    });
    this.fetchSchools();
  }

  fetchSchools = () => {
    axios.get('/api/school/', { params: { accounts_page: true } }).then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({ schools: r.data });
      }
    });
  };

  updateDistrict = (district) => {
    return axios
      .put(`/api/district/${district.id}/`, district)
      .then((r) => {
        this.setState({ district: r.data });
      })
      .catch((error) => {
        this.setState({ errors: error.response && error.response.data });
      });
  };

  updateSchool = (school) => {
    return axios
      .put(`/api/school/${school.id}/`, school)
      .then((r) => {
        let schools = this.state.schools;
        let index = _.findIndex(schools, (school) => school.id === r.data.id);
        schools.splice(index, 1, r.data);
        this.setState({ schools: schools });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data?.detail || error.response.data });
      });
  };

  createSchool = (school) => {
    return axios
      .post(`/api/school/`, school)
      .then((r) => {
        let schools = this.state.schools;
        schools.push(r.data);
        schools.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        this.setState({ schools: schools });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data?.detail || error.response.data });
      });
  };

  deleteSchool = (school) => {
    return axios
      .delete(`/api/school/${school.id}/`)
      .then((r) => {
        let schools = this.state.schools;
        let index = _.findIndex(schools, (s) => s.id === school.id);
        schools.splice(index, 1);
        this.setState({ schools: schools });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  updateUser = (user) => {
    return axios.put(`/api/user/${user.id}/`, user).then((r) => {
      if (!this.ignoreLastFetch) {
        let users = this.state.users;
        let index = _.findIndex(users, (user) => user.id === r.data.id);
        users.splice(index, 1, r.data);
        this.setState({ users: users });

        // also need to re-fetch schools to get the correct 'school_admins_exist' value
        this.fetchSchools();
      }
    });
  };

  createUser = (user) => {
    user['email'] = user['username'];
    return axios.post(`/api/user/`, user).then((r) => {
      if (!this.ignoreLastFetch) {
        let users = this.state.users;
        users.push(r.data);
        users.sort((a, b) => (a.last_name > b.last_name ? 1 : b.last_name > a.last_name ? -1 : 0));
        this.setState({ users: users });

        // also need to re-fetch schools to get the correct 'school_admins_exist' value
        this.fetchSchools();
      }
    });
  };

  deactivateUser = (user) => {
    return axios
      .put(`/api/user/${user.id}/deactivate_user/`)
      .then((r) => {
        if (!this.ignoreLastFetch) {
          let users = this.state.users;
          let index = _.findIndex(users, (u) => u.id === user.id);
          users[index] = r.data;
          this.setState({ users });

          // also need to re-fetch schools to get the correct 'school_admins_exist' value
          this.fetchSchools();
        }
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  reactivateUser = (user) => {
    return axios
      .put(`/api/user/${user.id}/reactivate_user/`)
      .then((r) => {
        if (!this.ignoreLastFetch) {
          let users = this.state.users;
          let index = _.findIndex(users, (u) => u.id === user.id);
          users[index] = r.data;
          this.setState({ users });

          // also need to re-fetch schools to get the correct 'school_admins_exist' value
          this.fetchSchools();
        }
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  };

  render() {
    return (
      <div>
        {this.state.district.id && this.state.schools && this.state.users && (
          <DistrictAccount
            updateUser={this.updateUser}
            createUser={this.createUser}
            deactivateUser={this.deactivateUser}
            reactivateUser={this.reactivateUser}
            district={this.state.district}
            schools={this.state.schools}
            users={this.state.users}
            updateDistrict={this.updateDistrict}
            updateSchool={this.updateSchool}
            createSchool={this.createSchool}
            deleteSchool={this.deleteSchool}
          />
        )}
        <ErrorSnackbar
          message={this.state.errors}
          open={this.state.errors}
          onClose={() => this.setState({ errors: null })}
          duration={6000}
        />
      </div>
    );
  }
}

DistrictAccountContainer.displayName = 'DistrictAccountContainer';
export default withRouter(DistrictAccountContainer);
