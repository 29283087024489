import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import PublicRoutes from 'components/PublicRoutes';
import FeatureContext from 'context/featureContext';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import LoadingSpinner from '../components/loadingSpinner';
import auth from '../utils/auth';
import { PROD_URL, STAGING_URL } from 'utils/constants';
import { createOrUpdateIntercomUser } from '../utils/intercom';
import { getHomeRoute, getUserTypeById, UserType, UserTypeId } from '../utils/userType';
import { Preference } from 'types';
import { ServerErrorModal } from '../sharedComponents/Error';

interface InnerCurrentUserPermissionsProviderProps {
  children: React.ReactNode;
  hasApplications: boolean;
  hasOnboardingModule: boolean;
  hasProspectsModule: boolean;
  isIndianaDistrict: boolean;
  isPostingOnly: boolean;
  userType: UserType;
}

export interface CurrentUserPermissionsContextValue {
  hasApplications: boolean;
  hasOnboardingModule: boolean;
  hasProspectsModule: boolean;
  isIndianaDistrict: boolean;
  isPostingOnly: boolean;
  userType: UserType;
}

export const CurrentUserPermissionsContext = createContext<CurrentUserPermissionsContextValue>({
  hasApplications: false,
  hasOnboardingModule: false,
  hasProspectsModule: false,
  isIndianaDistrict: false,
  isPostingOnly: true,
  userType: 'candidate',
});

const InnerCurrentUserPermissionsProvider: React.FC<InnerCurrentUserPermissionsProviderProps> = ({
  children,
  hasApplications,
  hasOnboardingModule,
  hasProspectsModule,
  isIndianaDistrict,
  isPostingOnly,
  userType,
}) => {
  const value = useMemo<CurrentUserPermissionsContextValue>(
    () => ({
      hasApplications,
      hasOnboardingModule,
      hasProspectsModule,
      isIndianaDistrict,
      isPostingOnly,
      userType,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasOnboardingModule, hasProspectsModule, isIndianaDistrict, isPostingOnly, userType]
  );

  return (
    <CurrentUserPermissionsContext.Provider value={value}>
      {children}
    </CurrentUserPermissionsContext.Provider>
  );
};

export interface District {
  has_onboarding_module: boolean;
  has_prospects_module: boolean;
  posting_only: boolean;
  indiana_district: boolean;
  id?: number;
  name?: string;
  parent_organization?: string;
}

export interface Profile {
  user_type: UserTypeId;
  district?: District;
}

export interface User {
  profile?: Profile;
  has_applications: boolean;
  enabled_features: string[];
  date_joined?: string;
  intercom_hmac?: string;
  id?: number;
  name?: string;
  email?: string;
  preference?: Preference;
}

interface CurrentUserPermissionsProviderProps {
  children: React.ReactNode;
}

interface LoadingState {
  kind: 'loading';
}

interface LoggedInState {
  kind: 'logged-in';
  user: User;
}

interface NotLoggedInState {
  kind: 'not-logged-in';
}

interface ErrorState {
  kind: 'error';
}

type UserState = LoadingState | LoggedInState | NotLoggedInState | ErrorState;

type SetUser = (user: User) => void;

const SetUserContext = createContext<SetUser>(() => {});

export const useSetUser = (): SetUser => useContext(SetUserContext);

const CurrentUserPermissionsProvider: React.FC<CurrentUserPermissionsProviderProps> = ({
  children,
}) => {
  const featureContext = useContext(FeatureContext);
  const history = useHistory();
  const location = useLocation();
  const [userState, setUserState] = useState<UserState>({ kind: 'loading' });
  const { pathname } = location;

  const setUser = useCallback((user: User) => {
    setUserState({ kind: 'logged-in', user });
  }, []);

  const setNotLoggedInUser = useCallback(() => {
    setUserState({ kind: 'not-logged-in' });
  }, []);

  // makes network request and saves user to state
  useEffect(() => {
    let isUnmounted = false;

    auth
      .checkAuthenticated()
      .then((user) => {
        if (isUnmounted) return;
        if (!user || Object.keys(user).length === 0) {
          setUserState({ kind: 'not-logged-in' });
          return;
        }

        setUserState({ kind: 'logged-in', user });
        featureContext.setFeatures([...(user?.enabled_features || [])]);
      })
      .catch(() => {
        setUserState({ kind: 'error' });
      });

    return () => {
      isUnmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.hostname === STAGING_URL || window.location.hostname === PROD_URL) {
      if (userState.kind === 'logged-in' && window.Intercom) {
        const { user } = userState;
        const application = window.location.pathname.includes('/connect') ? 'connect' : 'classic';
        if (user) {
          createOrUpdateIntercomUser(user, application);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Intercom, userState.kind]);

  // handle initial redirects
  useLayoutEffect(() => {
    if (userState.kind === 'logged-in' && pathname === '/') {
      const { user } = userState;

      const nextRoute = getHomeRoute({
        userType: getUserTypeById(user.profile.user_type),
        hasApplications: Boolean(user.has_applications),
      });

      history.push(nextRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);

  const loginMatch = useRouteMatch('/login');
  const connectMatch = useRouteMatch('/connect');
  const candidateMatch = useRouteMatch('/candidate');

  if (userState.kind === 'loading') {
    return (
      <SetUserContext.Provider value={setUser}>
        <LoadingSpinner />
      </SetUserContext.Provider>
    );
  }

  if (userState.kind === 'not-logged-in') {
    if (loginMatch) {
      return <SetUserContext.Provider value={setUser}>{children}</SetUserContext.Provider>;
    } else if (connectMatch || candidateMatch) {
      // Handle both /connect and /candidate routes
      return (
        <SetUserContext.Provider value={setNotLoggedInUser}>{children}</SetUserContext.Provider>
      );
    }

    const searchParams = new URLSearchParams(location.search);

    const returnTo = location.pathname;
    if (location.pathname.length > 1) {
      searchParams.set('return_to', returnTo);
    }

    // Specific redirect for /candidate/matches
    if (location.pathname === '/candidate/matches') {
      return (
        <SetUserContext.Provider value={setUser}>
          <Redirect
            to={{
              pathname: '/connect/signup',
              search: searchParams.toString(),
            }}
          />
        </SetUserContext.Provider>
      );
    }

    return (
      <SetUserContext.Provider value={setUser}>
        <PublicRoutes
          fallback={
            <Redirect
              to={{
                pathname: '/login',
                search: searchParams.toString(),
              }}
            />
          }
        />
      </SetUserContext.Provider>
    );
  }

  if (userState.kind === 'error') {
    return <ServerErrorModal />;
  }

  const { user } = userState;

  return (
    <>
      {user && (
        <InnerCurrentUserPermissionsProvider
          userType={getUserTypeById(user.profile.user_type)}
          hasOnboardingModule={Boolean(user?.profile?.district?.has_onboarding_module)}
          hasProspectsModule={Boolean(user?.profile?.district?.has_prospects_module)}
          isPostingOnly={Boolean(user?.profile?.district?.posting_only)}
          isIndianaDistrict={Boolean(user?.profile?.district?.indiana_district)}
          hasApplications={Boolean(user?.has_applications)}
        >
          <SetUserContext.Provider value={setUser}>{children}</SetUserContext.Provider>
        </InnerCurrentUserPermissionsProvider>
      )}
    </>
  );
};

export default CurrentUserPermissionsProvider;
