import React from 'react';
import { useRouteMatch } from 'react-router';
import { Box, Typography, Link, Stack, useMediaQuery, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import NimbleLogo from '../assets/nimble-navbar-v2-logo.svg';

export const Footer: React.FC = () => {
  const connectMatch = useRouteMatch('/connect');
  const candidateMatch = useRouteMatch('/candidate');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getSupportEmailInfo = () => {
    if (connectMatch || candidateMatch) {
      return {
        href: 'mailto:connectsupport@hirenimble.com',
        text: 'connectsupport@hirenimble.com',
      };
    }
    return {
      href: 'mailto:support@hirenimble.com',
      text: 'support@hirenimble.com',
    };
  };

  const { href, text } = getSupportEmailInfo();

  return (
    <FooterContainer className="footer">
      <CenteredContainer
        className="footer-content"
        justifyContent={connectMatch ? 'space-between' : 'center'}
      >
        {connectMatch && (
          <LogoContainer>
            <img src={NimbleLogo} alt="Nimble Logo" />
          </LogoContainer>
        )}
        <ContentContainer className="contact-support">
          <Typography variant="body2">
            {isMobile ? 'Questions? Contact us @ ' : "Questions? We're here to help. Contact "}
            <SupportLink href={href}>{text}</SupportLink>.
          </Typography>
        </ContentContainer>
      </CenteredContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '60px',
  alignItems: 'center',
  background: theme.palette.white.main,
  fontSize: theme.typography.body3.fontSize,
}));

const CenteredContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '60px',
  maxWidth: '1130px',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(2)}`,
  },
}));

const LogoContainer = styled(Box)(() => ({
  '& img': {
    height: '40px',
    width: 'auto',
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'end',
  },
}));

const SupportLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));
